import React from 'react';
import { WaveAboutUs } from '../../icons/WaveAboutUs';
import { Typography } from '../../../config/styled/typography';
import { StyledContainer } from '../../../config/styled/shared';
import * as S from './styles';
import { QuestionMark } from '../../icons/QuestionMark';
import { AboutUsLineLeft } from '../../icons/AboutUsLineLeft';
import { AboutUsLineRight } from '../../icons/AboutUsLineRight';

export const AboutUsTemplate = () => {
  return (
    <>
      <StyledContainer>
        <S.PageHeading as="h1">About Us</S.PageHeading>
      </StyledContainer>
      <S.Bottom>
        <WaveAboutUs />
        <StyledContainer>
          <S.Drawings>
            <S.QuestionMark>
              <QuestionMark />
            </S.QuestionMark>
            <S.LineOne>
              <AboutUsLineLeft />
            </S.LineOne>
            <S.LineTwo>
              <AboutUsLineRight />
            </S.LineTwo>
          </S.Drawings>
          <S.Texts>
            <Typography.L responsive css={{ color: '#ffffff', fontWeight: 400 }}>
              Hello, we are Bermond Management
              <br />
              <br />
              At Bermond Management, we strive to grow our clients' businesses and achieve their goals. Our specialists
              is passionate about intelligent marketing and makes it simple for you.
              <br />
              <br />
              Since 2015, we have grown leads and improved the bottom line for hundreds of clients across various
              industries. We provide our clients with powerful, proven marketing strategies and fresh ideas through
              coordinated teamwork.
              <br />
              <br />
              Now is the time to work with a team that you can trust and that is totally focused on your success!
            </Typography.L>
          </S.Texts>
        </StyledContainer>
      </S.Bottom>
    </>
  );
};
