import React from 'react';

export const WaveAboutUs = () => (
  <svg width="100%" height="100%" viewBox="0 0 1728 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M214 68C188.248 129.313 6.98786 30.5166 0 0H1076.71H1612H1728C1724.78 4.73318 1582.5 135.5 1638.5 69.5C1684.47 15.3183 1608.34 44.742 1612 0C1483.5 96.5 1496.05 20.8706 1443.67 47.5551C1378.19 80.9106 1416.28 24.2889 1269.5 91C1152.08 144.369 1076.28 47.7783 1076.71 0C1052 117.5 934.5 0 895.5 53.5C815.019 163.903 858.301 -27.1935 700.534 14.1995C574.319 47.3139 491.5 23.0308 491.5 4.5C474.361 22.4057 611.329 69.6739 560 91C495.838 117.658 256 -32 214 68Z"
      fill="#FBFBFB"
    />
  </svg>
);
