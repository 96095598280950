import React from 'react';

export const AboutUsLineRight = ({ className }) => (
  <svg
    width="455"
    height="914"
    className={className}
    viewBox="0 0 455 914"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 46.5C77.3151 39.7694 310.208 329.721 259.51 540.485C196.137 803.939 199.018 963.229 293.436 893.68C387.855 824.13 533.083 744.645 392.656 658.109C252.229 571.573 -74.6361 704.903 92.4368 439.846C259.51 174.79 293.756 18.7047 203.819 3"
      stroke="#008FE7"
      strokeWidth="6"
    />
  </svg>
);
