import styled from '@emotion/styled';
import { mq } from '../../../config/styled/media';
import { Typography } from '../../../config/styled/typography';

export const Texts = styled.div`
  padding: 63px 0 42px 0;
  width: 58%;
  max-width: 934px;
  ${mq.lt.md} {
    padding: 24px 0 32px 0;
    width: 100%;
  }
`;

export const Drawings = styled.div`
  width: 100%;
  position: relative;
`;

export const PageHeading = styled(Typography.XXL)`
  padding: 99px 0;
  ${mq.lt.lg} {
    padding: 36px 0;
    font-size: 5.6rem;
    line-height: 6.4rem;
  }
  ${mq.lt.sm} {
    padding: 24px 0;
    font-size: 4.8rem;
    line-height: 5.7rem;
  }
`;

export const Bottom = styled.div`
  position: relative;
  background-color: #000;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const QuestionMark = styled.div`
  position: absolute;
  right: 5%;
  top: 0;
  transform: translateY(-50%);
  width: 700px;
  z-index: 2;
  ${mq.lt.xl} {
    right: 0;
    width: 450px;
  }
  ${mq.lt.lg} {
    right: 0;
    width: 200px;
  }
  ${mq.lt.sm} {
    width: 170px;
    transform: translateY(-70%);
    right: -20%;
  }
`;

export const LineOne = styled.div`
  position: absolute;
  right: 15%;
  top: 0;
  transform: translateY(-40%);
  z-index: 1;
  ${mq.lt.xl} {
    right: 12%;
    transform: translateY(-50%);
    svg {
      width: 300px;
    }
  }
  ${mq.lt.lg} {
    display: none;
  }
  ${mq.lt.sm} {
    display: none;
  }
`;

export const LineTwo = styled.div`
  position: absolute;
  right: 5%;
  top: 0;
  transform: translateY(-42%);
  z-index: 1;
  ${mq.lt.xl} {
    right: 0%;
    top: 5%;
    svg {
      width: 350px;
    }
  }
  ${mq.lt.lg} {
    display: none;
  }
  ${mq.lt.sm} {
    display: none;
  }
`;
