import React from 'react';

export const AboutUsLineLeft = ({ className }) => (
  <svg
    width="471"
    height="738"
    className={className}
    viewBox="0 0 471 738"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.17546 123.309C-28.8826 28.9491 168.111 -58.6169 204.252 62.4335C238.172 176.047 54.173 186.953 77.4494 262.497C100.726 338.041 314.783 81.0735 117.511 447.093M245.036 452.043C184.819 370.489 352.436 234.922 418.675 342.493C480.845 443.456 305.938 501.613 347.974 568.559C390.01 635.505 530.265 331.891 434.447 736.496"
      stroke="#FF8310"
      strokeWidth="6"
    />
  </svg>
);
