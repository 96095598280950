import * as React from 'react';
import { Layout } from '../components/layout';
import { AboutUsTemplate } from '../components/templates/about-us';

const AboutUsPage = () => {
  return (
    <Layout>
      <AboutUsTemplate />
    </Layout>
  );
};

export default AboutUsPage;

export const Head = () => <title>Bermond Management</title>;
